.stoppuhr-js {
	width: 500px;
}



.stoppuhr-finished {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: default;
	background: #000d;
}
.stoppuhr-finished > div {
	max-width: 90%;
	max-height: 80%;
}
button {
	padding: 7px 13px;
	border: 1px solid #000;
	border-radius: 5px;
	background: #fff;
	cursor: pointer;
	margin: 10px 7px;
}
a {
	color: #006db5
}
.stoppuhr-finished .small {
	text-align: center;
	font-size: 12px;
}
.stoppuhr-finished .inner-container {
	margin: 0 auto;
}
.stoppuhr-finished h3 {
	margin: 0;
}
.text-center {
	text-align: center;
}

code {
	font-size: 2.1em;
	text-align: center;
	display: block;
	font-family: "Consolas", "Courier New", monospace;
	margin: 10px 0;
}
h4 {
	text-align: center;
	margin: 3px 0;
}
.inner-container {
	font-family: "Consolas", "Courier New", monospace;
	font-size: 2.5em;
}

.stoppuhr-outer {
	cursor: pointer;
}
.running-circle {
	border: 1px solid #ccc;
	width: 64%;
	height: 64%;
	border-radius: 100%;

	display: flex;
	align-items: center;
	justify-content: center;
}